@import '../../styles/variables.css';

.hero {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 20px; */
    gap: 40px;
    background-color: var(--secondary-color);

}

.wrapper {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    gap: 10px;
    align-items: center;
}

.title {
    color: var(--accent-color);
}