.formLabel {
    color: var(--darken-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 20px;
  
}

.formInput {
    display: block;

    margin: 8px 0 24px 0;
    padding-left: 15px;
    padding-right: 15px;

    width: 100%;
    height: 58px;

    color: var(--darken-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;

    border-radius: 30px;
    border: 1px solid var(--input-border-color);
    outline: none;
    background-color: var(--secondary-color);

    transition: border-color 0.15s ease-in-out, background-image 0.15s ease-in-out;

    @media (min-width: 768px) {
        font-size: 16px;
    }
}

.formInput:focus {
    border-color: var(--accent-color);

}


.formInput::placeholder {
    color: var(--text-color);
}


.formSelect {
    composes: formInput;
    color: var(--text-color);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    background-image: url(../../../src/assets/icons/selectarrow.png);
    background-repeat: no-repeat;
    background-position: 95% 50%;
    padding-right: 60px;
    height: 78px;
    white-space: normal;
    /* For Firefox: */
    text-overflow: ellipsis;

}

.formSelect::-ms-expand {
    display: none;
    /* remove arrow IE     */
}

.formSelect:focus {
    background-image: url(../../../src/assets/icons/chosenselectarrow.png);
}

.formOption {
    /* wrap text in compatible browsers */
    -moz-white-space: pre-wrap;
    -o-white-space: pre-wrap;
    white-space: pre-wrap;
    /* hide text that can't wrap with an ellipsis */
    overflow: hidden;
    text-overflow: ellipsis;

}

.container{
    display: flex;
    justify-content: space-between;

}

.avatarWrapper{
   position: relative;
    
}

.butWrapper{
    position: absolute;
    right: 1px;
    bottom: 1px;

}