.wrapper {
    /* width: 50px; */
    /* height: 50px; */
    /* margin-top: 18px; s */
}

.icon {
    width: 24px;
    height: 24px;

    fill: currentColor;

    @media (min-width: 1200px) {
        width: 48px;
        height: 48px;
    }
}