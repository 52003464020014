@import '../../styles/variables.css';

.wrapper{
    display: flex;
    gap: 8px;
    align-items: center;
    @media (min-width: 768px) {
        gap: 14px;
    }
}


.userName{
    color: var(--darken-color);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    line-height: 1.28;

    @media (min-width: 768px) {
        font-size: 18px;
        line-height: normal;
    }
}


.userPhotoWrapper{
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1.8px solid var(--accent-color);
    border-radius: 50%;
  
    overflow: hidden;
    
    @media (min-width: 768px) {
        width: 44px;
        height: 44px;
    }
}

.userPhoto{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

.text{
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}