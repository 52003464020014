@import '../../styles/variables.css';


.wrapper {
  display: flex;
  justify-content: center;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 76px;
  height: 34px;

  cursor: pointer;

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.28;
  text-align: center;

  color: var(--first-main-color);
  background-color: var(--second-main-color);

  border: none;
  border: 1px solid var(--div-border-color);

  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 1.12;
  }
}

.link:hover,
.link:focus {
  background-color: var(--paginator-bg-color);
}

.left {
  border-left: 1px solid var(--paginator-border-color);
  border-right: 0px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.right {
  border-left: 0px;
  border-right: 1px solid var(--paginator-border-color);
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.center {
  background-color: var(--second-main-color);
  border-left: 1px solid var(--paginator-border-color);
  border-right: 1px solid var(--paginator-border-color);
}