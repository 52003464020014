@import '../../styles/variables.css';

.link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    /* padding: 10px 11px; */
    padding: 14px 15px;
    border-radius: 16px;

    font-weight: 600;
    font-size: 14px;

    background-color: var(--accent-color);
    color: var(--secondary-color);

    box-shadow: 4px 2px 16px 0px #88A5BF7A;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;


}

.link:hover,
.link:focus {
    color: var(--accent-color);
    background-color: var(--secondary-color);
    border: 1px solid var(--accent-color);
    box-shadow: 4px 2px 20px 0px #29394679;

}