@import '../../styles/variables.css';

/*
 * Стили компонента Modal
 */

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 50;
}

.Modal {
  width: 100%;
  max-width: 303px;
  height: 336px;
  /* overflow: scroll; */
  /* min-width: 303px;
    min-height: 336px; */
  background-color: var(--secondary-color);
  padding: 38px 15px;
  border-radius: 8px;
  border: 1px solid #DCE3E5CC;
  transform: scale(1.1);
  transition: transform 200ms ease-in-out;

  @media (min-width: 1200px) {
    padding: 50px 25px;
    max-width: 396px;
    max-height: 360px;
    /* min-width: 396px;
      min-height: 360px; */
  }
}

.modalContent {
  height: 240px;
  overflow: scroll;
}