@import '../../../styles/variables.css';

.mobileMenuBtn{
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;

}

.mobileMenuBtn:disabled > svg {
    stroke: var(--secondary-color); 
}

.icon{
    stroke: var(--darken-color); 
    width: 24px;
    height: 24px;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
   
    @media (min-width: 768px) {
        width: 34px;
        height: 34px;
    } 
    @media (min-width: 1200px) {
        pointer-events: none;
        display: none;
    }
}

.icon:hover,
.icon:focus {
    stroke: var(--accent-color);
}