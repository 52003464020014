.toolButton{
    background-color: transparent;
    border: none;
    padding: 0;
    color: #3E85F3;
}

.toolButtonIcon{
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    fill: currentColor;
    /* stroke: currentColor; */
  

}

.toolButtonIcon:hover,
.toolButtonIcon:focus,
.toolButtonIcon:active {
    fill: var(--accent-color);
}

.small{
    composes: toolButtonIcon;
    width: 26px;
    height: 26px;
}

.big{
    composes: toolButtonIcon;
    width: 48px;
    height: 48px;
}

