@import '../../styles/variables.css';

.mainContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: var(--price-bg-color);

}

.formContainer {
    /* width: 100%; */
    min-width: 350px;
    /* height: 550px; */
    border-radius: 8px;
    background-color: var(--secondary-color);
    padding: 40px 24px;
    margin: auto;

    @media (min-width: 768px) {
        width: 600px;
        padding: 40px;
    }
}

.logoWrapper {
    display: flex;
    /* gap: 10px; */
    gap: 5px;
    font-size: 9pt;
    align-items: flex-end;
    margin-top: 0;
    /* margin-bottom: 32px; */
    margin-bottom: 18px;

    @media (min-width: 768px) {
        margin-bottom: 40px;
    }


}

.title {
    margin-bottom: 16px;
    color: var(--accent-color);

    font-size: 18px;
    font-weight: 600;
    line-height: 1.3333;

    text-shadow: 0px 9.399999618530273px 57.6875px rgba(0, 0, 0, 0.04),
        0px 47px 355px rgba(0, 0, 0, 0.07);
    /* text-transform: capitalize; */

    @media (min-width: 768px) {
        font-size: 24px;
    }
}

.label {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 48px;
}

.labelText {
    color: var(--darken-color);
    /* font-size: 12px; */
    font-weight: 600;
    line-height: 1.5;

    margin-bottom: 8px;

    @media (min-width: 768px) {
        font-size: 16px;
    }
}

.accent {
    color: var(--accent-color);
    font-weight: 700;

}

.msgText {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.1667px;

    margin-top: 8px;
    margin-bottom: -22px;
    padding-left: 12px;

}

.errorMsg {
    color: var(--error-message-color);
}

.input {
    width: 100%;
    height: 46px;

    padding: 0;
    padding-left: 12px;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    background-color: var(--secondary-color);

    color: var(--darken-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2857;
    outline: none;

    @media (min-width: 768px) {

        height: 54px;
        padding-left: 18px;
        font-size: 16px;
    }

}

.input::placeholder {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2857;
}

.input:focus {
    border-color: var(--accent-color);
}