@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-400.woff2') format('woff2'),
    url('./assets/fonts/Inter-400.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-500.woff2') format('woff2'),
    url('./assets/fonts/Inter-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-600.woff2') format('woff2'),
    url('./assets/fonts/Inter-600.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-700.woff2') format('woff2'),
    url('./assets/fonts/Inter-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/poppins-v20-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/poppins-v20-latin-500.woff2') format('woff2');
}

@import 'styles/variables.css';

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--main-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.caption {
  margin-bottom: 10px;
  color: rgb(87, 170, 255);
}

a {
  text-decoration: none;
  color: inherit;

}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: 576px) {
    max-width: 540px;
  }

  @media screen and (min-width: 768px) {
    max-width: 720px;

  }

  @media screen and (min-width: 992px) {
    max-width: 960px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1140px;
  }
}

/* .rotateImage {
 animation: rotateAnimation 4s linear infinite; */
/* Анімація обертання */
/* }

@keyframes rotateAnimation {
 0% {
   transform: rotateY(0deg); */
/* Початкове положення - не обернута */
/* }

 100% {
   transform: rotateY(360deg); */
/* Закінчене положення - обернута на 360 градусів */
/* }
} */

/* Navigation Bar */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap"); */

/* @import url("https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"); */

/** 
@chrisbautista @codespud
animated tabs using only css transitions.

I wanted to test how much we can accomplish with just CSS transitions. No keyframe animations at all.
**/

:root {
  /* --accent-color: #1fa8f5; */
  --accent-color: #fe5f55;
  ;
  /* --accent-color-fg: #fefefe; */
  /* --backdrop-color: #89d4fe; */
  /* --app-content-background-color: #c0d8ec; */
  /* --inset-shadow: rgba(7, 43, 74, 0.3); */
  /* --outset-shadow: rgba(223, 240, 255, 0.25); */
  /* --clay-box-shadow: rgba(7, 43, 74, 0.3); */
  --clay-box-shadow: 4px 2px 16px rgba(136, 165, 191, 0.48);
  /* --clay-background-color: #c0d8ec; */
  --clay-background-color: #ff948d;
  /* --clay-fg-color: #444; */
}

body {
  /* background-color: var(--backdrop-color); */
  /* font-size: 10px; */
  font-family: "Roboto", sans-serif;
}

.flex-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* .container {
  padding: 1rem 1rem 1.5rem;
} */

/* // start here */

.stage {
  max-width: 400px;
  width: 400px;
  margin: 1rem auto 2rem;
}

.account {
  &.active {
    /* color: var(--accent-color); */
    /* fill: var(--accent-color); */
  }

  &-style {
    /* --app-content-background-color: #c0d8ec; */
  }
}

.schedule {
  &.active {
    --outset-shadow: rgba(247, 167, 103, 0.45);
    --inset-shadow: rgba(149, 62, 8, 0.45);
    --clay-box-shadow: rgba(211, 69, 20, 0.4);
    /* --clay-background-color: #d34514; */
    --clay-fg-color: #f1f2f3;

    /* color: #690c0c; */
    color: var(--accent-color);
    /* fill: var(--accent-color); */
  }

  &-style {
    --app-content-background-color: #d36e5a;
  }
}

.calendar {
  &.active {
    --outset-shadow: rgba(255, 159, 40, 0.45);
    --inset-shadow: rgba(88, 54, 13, 0.45);
    --clay-box-shadow: rgba(88, 54, 13, 0.4);
    /* --clay-background-color: #ed9426; */
    --clay-fg-color: #f1f2f3;

    /* color: #cf5c0f; */
    color: var(--accent-color);
    /* color: var(--accent-color); */
    /* fill: var(--accent-color); */
    fill: var(--clay-background-color);
  }

  &-style {
    --app-content-background-color: #ed9426;
  }
}

.add_schedule {
  &.active {
    /* --outset-shadow: rgba(28, 116, 23, 0.45); */
    --outset-shadow: #1e8449;
    --inset-shadow: rgba(28, 78, 26, 0.45);
    --clay-box-shadow: rgba(28, 78, 26, 0.4);
    /* --clay-background-color: #4dd146; */
    --clay-fg-color: #f1f2f3;

    /* color: #4dd146; */
    /* color: var(--outset-shadow); */
    color: var(--accent-color);
    /* fill: var(--accent-color); */
  }

  &-style {
    --app-content-background-color: #4dd146;
  }
}

.add_appointment {
  &.active {
    --outset-shadow: rgba(230, 230, 230, 0.45);
    --inset-shadow: rgba(81, 81, 81, 0.45);
    --clay-box-shadow: rgba(81, 81, 81, 0.4);
    /* --clay-background-color: #a3a3a3; */
    /* --clay-background-color: #af7ac5; */
    --clay-fg-color: #f1f2f3;

    /* color: #783896; */
    color: var(--accent-color);
    /* fill: var(--accent-color); */
  }
}

&-style {
  --app-content-background-color: #a3a3a3;
}

.admin {
  &.active {
    --outset-shadow: rgba(230, 230, 230, 0.45);
    --inset-shadow: rgba(81, 81, 81, 0.45);
    /* --clay-box-shadow: rgba(81, 81, 81, 0.4); */
    --clay-box-shadow: 4px 2px 16px rgba(136, 165, 191, 0.48);
    /* --clay-background-color: #a3a3a3; */
    /* --clay-background-color: #ff948d; */
    --clay-fg-color: #f1f2f3;

    color: var(--accent-color);
    /* color: rgb(87, 170, 255); */
    /* color: var(--accent-color); */
    /* fill: var(--accent-color); */
    /* fill: #783896; */
  }

  /* &-style {
    --app-content-background-color: #a3a3a3;
  } */
}

.tabbar {
  /* width: 378px; */
  margin: 0 auto;
  /* background-color: var(--app-content-background-color); */
  background-color: white;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* height: 120px; */
  height: 75px;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
  transition: background-color 0.4s;

  /* */
  /* width: inherit;
  position: fixed;
  bottom: 0px;
  left: 0; */
  /*  */

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    /* background-color: #f9f8fa; */
    align-self: flex-end;
    /* justify-content: center; */
  }

  li {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    /* margin-right: 5px; */
    transition: all 0.4s;
    /* background-color: #f9f8fa; */
    width: 60px;
    height: 60px;
    position: relative;
    /* color: #888; */
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }
}

.tab-style1 {
  li {
    width: 60px;
    height: 40px;
    flex-direction: column;
    /* overflow: hidden; */

    span {
      position: relative;
      top: 3px;
      transition: top 0.4s ease-out, font-size 0.2s ease-out;
      text-shadow: inset 2px 2px 4px var(--clay-box-shadow);
    }

    &:after {
      width: 100%;
      /* height: 10px; */
      height: 15px;
      /* text-transform: uppercase;  */
      text-align: center;
      /* font-size: 8px; */
      font-size: 11px;
      font-weight: bold;
      position: relative;
      bottom: -30px;
      opacity: 0;
      transition: bottom 0.2s ease-out, opacity 0.4s ease-out;

      /* text-shadow: 4px 2px 16px rgba(136, 165, 191, 0.48); */
    }

    &.active:after {
      opacity: 1;
      bottom: 6px;
      text-shadow: inset 2px 2px 8px var(--clay-box-shadow);
    }

    &:nth-child(1) {
      &:after {
        content: "Розклад";
      }
    }

    &:nth-child(2) {
      &:after {
        content: "Прийоми";
      }
    }

    &:nth-child(3) {
      &:after {
        content: "Запис у графік";
      }
    }

    &:nth-child(4) {
      &:after {
        content: "Запис на прийом";
      }
    }

    &:nth-child(5) {
      &:after {
        content: "Адмін панель";
      }
    }

    /* &:nth-child(6) {
      &:after {
        content: "admin";
      }
    } */
  }

  .active {
    /* fill: var(--accent-color); */
    fill: var(--clay-background-color);
    /* fill: red; */

    span {
      font-size: 1.1rem;
      top: -8px;
      /*  */
      /* fill: var(--accent-color); */
      /* fill: red; */
    }
  }
}

.site-url-container {
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(#fff, 0.5);
  padding: 0.5rem 0.8rem;
}

.tabbar-position {
  width: inherit;
  position: fixed;
  bottom: 0px;
  left: 0;
}

.tabbar-list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* text-shadow: 4px 2px 16px rgba(136, 165, 191, 0.48); */
}


.active svg {
  fill: var(--accent-color);
  /* fill: var(--clay-background-color); */
}

/* button LogOut */
.btnLogOut {
  width: 32px;
  height: 32px;
}

.formsButton {
  height: 46px;
}