@import '../../styles/variables.css';

.circleWrapper{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid var(--first-main-color);
    color: var(--first-main-color);

}

.defaultAvatar{
    width: 100%;
    height: 100%;
    object-fit: cover;
    fill: currentColor;
}

.avatarImage{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

}