@import '../../../styles/variables.css';

.toolButton {
    background-color: transparent;
    border: none;
    padding: 0;
    color: var(--first-main-color);
    width: 26px;
    height: 26px;
}


.toolButtonIcon {
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    /* stroke: currentColor; */
    fill: currentColor;
    width: 100%;
    height: 100%;

}

.toolButtonIcon:hover,
.toolButtonIcon:focus,
.toolButtonIcon:active {
    fill: var(--accent-color);
}

.toolButton:disabled .toolButtonIcon {
    fill: var(--link-color);
    cursor: not-allowed;

}