@import '../../../styles/variables.css';

.wrapper {
    /* margin-top: 18px; */


}

.link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 16px;
    color: var(--secondary-color);
    background-color: var(--accent-color);
    box-shadow: 4px 2px 16px 0px #88A5BF7A;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;

    @media (min-width: 1200px) {
        width: 60px;
        height: 60px;
    }
}

.link:hover,
.link:focus {
    color: var(--accent-color);
    background-color: var(--secondary-color);
}

.icon {
    width: 24px;
    height: 24px;

    fill: currentColor;

    @media (min-width: 1200px) {
        width: 36px;
        height: 36px;
    }
}