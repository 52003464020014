.wrapper {
    /* margin-bottom: 14px; */
    margin-bottom: 10px;

    @media (min-width: 768px) {
        margin-bottom: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        line-height: 1.12;
    }



}

/* Здесь менялись margin-bottom */