@import '../../styles/variables.css';

.wrapper {
    margin-bottom: 18px;

    @media (min-width: 320px) {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    @media (min-width: 768px) {
        justify-content: baseline;
        margin-bottom: 0;
    }
}


.btnWrapper {
    display: flex;
    margin-top: 14px;

    @media (min-width: 320px) {
        margin-top: 0;
    }
}

.paginatorBtn {
    width: 36px;
    height: 30px;

    cursor: pointer;

    background-color: var(--secondary-color);
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

    border: 1px solid var(--div-border-color);

    @media (min-width: 768px) {
        width: 38px;
        height: 34px;
    }
}

.paginatorBtnLeft {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}


.paginatorBtnRight {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}


.paginatorBtn:hover,
.paginatorBtn:focus {
    background-color: var(--calendar-bg-color);
}

.paginatorBtn>svg {
    width: 16px;
    height: 16px;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    stroke: var(--calendar-text-color);

    @media (min-width: var(--tablet)) {
        width: 18px;
        height: 18px;
    }
}