@import '../../styles/variables.css';

.menu{
    display: flex;
    flex-direction: column;
    gap: 18px;
    background-color: var(--secondary-color);
    border-radius: 12px;
    margin-top: 24px;

    @media (min-width: 768px) {
        gap: 16px;
    }
}

.link{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    color: var(--link-color);

    font-size: 14px;
    font-weight: 600;

    border-radius: 8px;
    padding: 10px 14px;
    
    @media (min-width: 768px) {
        font-size: 16px;
        gap: 10px;
        padding: 16px 20px;
    } 
}

.link:hover,
.link:focus{
    color: var(--accent-color);
    background-color: var(--second-main-color);
}

.icon{
    width: 36px;
    height: 36px;

    fill: currentColor;
   
    @media (min-width: 1200px) {
        width: 48px;
        height: 48px;
    }
}
