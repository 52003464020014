@import '../../../styles/variables.css';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;

  /* width: 100%; */
  /* height: 100%; */
  /* height: 46px; */
  /* height: 46px; */
  padding: 0 10px;

  border-radius: 16px;
  border: none;
  background-color: var(--accent-color);
  box-shadow: 4px 2px 16px 0px rgba(136, 165, 191, 0.48);

  color: var(--secondary-color);

  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.28px;


  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);


  @media (min-width: 768px) {
    height: 56px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
  }


}

.button:focus,
.button:hover {
  color: var(--accent-color);
  background-color: var(--secondary-color);
  border: 1px solid var(--accent-color);
  box-shadow: 4px 2px 20px 0px #29394679;
}

.cancel {
  .cancel {
    color: #111111;
    background-color: #EFEFEF;
    border: 1px solid #111111;
  }

  .cancel:hover,
  .cancel:focus {
    .cancel:focus {
      color: var(--secondary-color);
      background-color: var(--accent-color);
      border: 1px solid var(--accent-color);
    }

    .ok {
      .ok {
        color: var(--accent-color);
        background-color: var(--secondary-color);
        border: 1px solid var(--accent-color);
      }

      .ok:hover,
      .ok:focus {
        .ok:focus {
          color: var(--secondary-color);
          background-color: var(--accent-color);
          border: 1px solid var(--accent-color);

        }
      }
    }
  }
}