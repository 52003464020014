.formLabel {
    color: var(--darken-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 20px;
  
}

.formInput {
    display: block;

    margin: 8px 0 24px 0;
    padding-left: 15px;
    padding-right: 15px;

    width: 100%;
    height: 58px;

    color: var(--darken-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;

    border-radius: 30px;
    border: 1px solid var(--input-border-color);
    outline: none;
    background-color: var(--secondary-color);

    transition: border-color 0.15s ease-in-out, background-image 0.15s ease-in-out;

    @media (min-width: 768px) {
        font-size: 16px;
    }
}

.formInput:focus {
    border-color: var(--accent-color);

}


.formInput::placeholder {
    color: var(--text-color);
}

.butWrapper{
    display: flex;
    flex-direction: column;
    gap: 50px;
  
    @media (min-width: 768px) {
        flex-direction: row;
    }
}