.butWrapper {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (min-width: 768px) {
        flex-direction: row;
        gap: 50px;
    }
}

.doctorsWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    margin-top: 12px;
    margin-bottom: 20px;
    width: 270px;

    /* overflow: auto; */



}

.wrapper {
    overflow: auto;

}


/* для элемента input c type="radio" */
.customRadio>input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

/* для элемента label связанного с .custom-radio */
.customRadio>span {
    display: inline-flex;
    align-items: center;
    user-select: none;
}


/* создание в label псевдоэлемента  before со следующими стилями */
.customRadio>span::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid var(--input-border-color);
    border-radius: 50%;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    background-color: var(--secondary-color);
}

/* стили при наведении курсора на радио */
.customRadio>input:not(:disabled):not(:checked)+span:hover::before {
    border-color: var(--first-main-color);
}

/* стили для активной радиокнопки (при нажатии на неё) */
.customRadio>input:not(:disabled):active+span::before {
    background-color: var(--second-main-color);
    border-color: var(--second-main-color);
}

/* стили для радиокнопки, находящейся в фокусе */
.customRadio>input:focus+span::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* стили для радиокнопки, находящейся в состоянии checked */
.customRadio>input:checked+span::before {
    border-color: var(--first-main-color);
    background-color: var(--first-main-color);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}