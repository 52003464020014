@import '../../styles/variables.css';

.wrapper{
    display: flex;
    justify-content: flex-end;

}

.toolList{
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 12px;
    position: relative;

    @media screen and (min-width: 768px) {
        gap: 26px;
    }
}

.toolButton{
    background-color: transparent;
    border: none;
    padding: 0;
}

.toolButtonIcon{
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    stroke: var(--first-main-color);
    width: 26px;
    height: 26px;

}

.toolButtonIcon:hover,
.toolButtonIcon:focus,
.toolButtonIcon:active {
    stroke: var(--accent-color);
}

