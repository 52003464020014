@import './variables.css';

.react-datepicker__day--disabled {
  visibility: hidden;
  pointer-events: none;
}

.react-datepicker {
  border-radius: 16px;
  border: 0;
  /* width: 100%; */

  @media screen and (min-width: 330px) {
    /* width: 327px; */
  }

  @media screen and (min-width: 768px) {
    width: 373px;
  }
}

.react-datepicker__month-container {
  /* background-color: #3e85f3; */
  /* background-color: var(--accent-color);  */
  border: 1px solid var(--accent-color);
  /* color: black; */
  border-radius: 16px;
  width: 100%;
}

.react-datepicker__month {
  border-radius: 16px;
  background-color: var(--secondary-color);
  /* background-color: #3e85f3; */
  /* background-color: var(--accent-color); */
  /* background-color: rgb(87, 170, 255); */
  ;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 16px;
}

.react-datepicker__header {
  /* background-color: #3e85f3; */
  background-color: var(--accent-color);
  border-radius: 16px;
  border: 0;
  border-bottom: 1px solid #ffffff33;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}

.react-datepicker__day {
  /* color: #ffffff; */
  color: var(--blue-logo);
  width: 26px;
  height: 26px;
  padding: 6px 6px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.28;
  border-radius: 50%;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

  @media screen and (min-width: 330px) {
    width: 40px;
    height: 40px;
    padding: 10px 10px;
    font-size: 14px;
  }

  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 1.3;
    width: 46px;
    height: 46px;
    padding: 12px 12px;
  }
}

.react-datepicker__day:hover,
.react-datepicker__day:focus {
  border-radius: 50%;
  background-color: #ffffff;
  /* color: #3e85f3; */
  color: var(--accent-color);
}

.react-datepicker__day-names {
  border-top: 1px solid var(--secondary-color)
}

.react-datepicker__day-name {
  color: #ffffff;
  width: 26px;
  height: 26px;
  padding: 6px 6px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.28;

  @media screen and (min-width: 330px) {
    width: 40px;
    height: 40px;
    padding: 10px 10px;
    font-size: 14px;
  }

  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 1.3;
    width: 46px;
    height: 46px;
    padding: 12px 12px;
  }
}

.react-datepicker__day--selected {
  border-radius: 50%;
  /* background-color: #ffffff; */
  background-color: var(--secondary-color);
  /* color: #3e85f3; */
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
}

.react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  background-color: #ffffff;
  color: #3e85f3;
}

.react-datepicker__navigation-icon::before {
  border-color: #ffffff;
  top: 11px;
}

.react-datepicker__navigation:hover *::before {
  border-color: #34343480;
}


.react-datepicker__input-container {
  color: rgba(0, 0, 0, 0.1);
}

.react-datepicker-wrapper .react-datepicker__input-container svg {
  padding: 0.9rem;
  fill: #3e85f3;
}

.react-datepicker__calendar-icon {
  padding: 0;
}

.react-datepicker-wrapper input {
  height: 50px;
  padding: 6px 10px 5px 34px;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  outline: none;
}

/* Стилизация фокуса input */
.react-datepicker-wrapper input:focus {
  border-color: #3e85f3;
}

.react-datepicker-popper {
  width: 150px;

  @media screen and (min-width: 330px) {
    /* left: 20px; */
    /* margin-right: 20px; */
    /* margin-left: 55px; */
  }
}

.react-datepicker__current-month {
  color: #ffffff;
  margin-bottom: 10px;
  /* color: var(--accent-color); */
}

.react-datepicker__day.sunday {
  color: #ccc;

}

.react-datepicker__day.sunday:hover {
  background-color: #3e85f3;
  color: #ccc;
  cursor: context-menu;
}

/*  */
.react-datepicker-wrapper {
  border: 0;
  display: inline-block;
  padding: 0;
}

.react-datepicker__input-container {
  display: inline-block;
  position: relative;
  width: 100%;
}

/* кнопка вибору дати */
.example-custom-input {
  /* background-color: #216ba5; */
  background-color: var(--accent-color);
  border: 0;
  border-radius: 16px;
  color: #fff;
  cursor: pointer;
  font: inherit;
  padding: 5px 15px;
}