@import '../../styles/variables.css';

.wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 15px;
}

.datePickerButton{
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
}

.datePickerButton > svg {
    width: 16px;
    height: 16px;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

    @media (min-width: 768px) {
        width: 18px;
        height: 18px;
    }

}

.headerTitle{
    color: var(--secondary-color);

    font-size: 20px;
    line-height: 1.2;
    font-family: 'Inter', sans-serif;
    font-weight: 600;

    @media (min-width: 768px) {
        font-size: 24px;
        line-height: 1.16;
    }

}

.calendarBtn{
    width: 100%;
    height: 100%;
    background-color: var(--first-main-color);
    padding: 6px 12px;
    border: 0;
    border-radius: 8px;

    color: var(--secondary-color);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 1.28;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    cursor: pointer;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

    @media (min-width: 320px) {
        width: 170px;
        height: 30px;
    }
    @media (min-width: 768px) {
        margin-right: 8px;
        width: 200px;
        height: 34px;
        font-size: 16px;
        line-height: 1.12;
        padding: 8px 12px;
    }

}