@import '../../styles/variables.css';

.header {
    /* <<<<<<< NavigationBar */
    /* padding-top: 4px; */
    /* padding-bottom: 4px; */
    background-color: var(--main-bg-color);
    /* ======= */
    padding-top: 10px;
    /* background-color: var(--main-bg-color); */
    /* background-color: var(--second-main-color); */
    /* position: fixed; */
    /* >>>>>>> main */

    @media (min-width: 1200px) {
        padding-top: 41px;
    }
}

.wrapper {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
}

.titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.motivationWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}