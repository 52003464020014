:root {
  --background-color: rgba(255, 255, 255, 0.5);
  --primary-color: #000000;
  --secondary-color: #FFFFFF;
  --accent-color: #fe5f55;
  --first-main-color: #3E85F3;
  --second-main-color: #E3F3FF;
  --paginator-bg-color: #CAE8FF;
  --paginator-border-color: #3E85F333;
  --darken-color: #232931;
  --blue-logo: rgb(87, 170, 255);
  /* --hero-bg-color: #e8dbce;
  --icon-bg-color: #ffeeed;
  --services-bg-color: #f8f9fa; */
  --text-color: #999999;
  --second-text-color: rgb(17, 17, 17);
  --footer-text-color: rgba(0, 0, 0, 0.7);
  --dark-color: #111111;
  --calendar-text-color: #343434;
  --disabled-btn-color: #d9dadc;
  --price-bg-color: #e5e5ef;
  --input-border-color: rgba(0, 0, 0, 0.1);
  --textarea-border-color: rgba(33, 33, 33, 0.2);
  --div-border-color: #DCE3E5;
  --line-color: #e1e1e1;
  --link-color: rgba(52, 52, 52, 0.5);
  --calendar-bg-color: rgba(220, 227, 229, 0.50);
  --main-bg-color: #F7F6F9;
  --disable-edit: oldlace;

  --doctor-first-color: rgb(173, 227, 248);
  --doctor-second-color: rgb(196, 172, 247);

  --error-message-color: #da1414;
  /* --error-color: #E74A3B; */
  --correct-message-color: #3cbc81;
  /* --placeholder-color: #dce3e5; */


  --mobile: 480px;
  --tablet: 768px;
  --desktop: 1200px;
}