@import '../../styles/variables.css';

.wrapper {
    padding-left: 10px;
    padding-right: 10px;
    /* max-width: 375px; */
    /* margin: 0 auto 100px auto; */
    margin: 0 auto;
    /* height: 100%; */
    margin-bottom: 85px;
    /* background-color: var(--main-bg-color); */

    @media (min-width: 768px) {
        max-width: 768px;
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (min-width: 1200px) {
        padding: 0;
        max-width: 1440px;
        display: flex;
        flex-direction: row;
        width: 100%;
    }
}

.mainContent {
    width: 100%;

    @media (min-width: 1200px) {
        padding-left: 32px;
        padding-right: 32px;
    }

}

.outletWrapper {
    /* padding-top: 60px; */
    /* padding-bottom: 20px; */
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */

    @media (min-width: 1200px) {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}