.item{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 10px;
}


.textWrapper{
    display: flex;
    flex-wrap: wrap;
    padding-left: 8px;
    @media (min-width: 320px) {
        row-gap: 8px;
    }

}

.textTitle{
    width: 150px;
}

.wrapper {
    width: 100%;
    padding: 16px;
    color: var(--second-text-color);
    background-color: var(--secondary-color);
    border: 1px dashed var(--accent-color);
    border-radius: 8px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.28;

    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

}


.butWrapper{
    display: flex;
    justify-content: flex-end;
    margin: 12px;


}

.userPhoto{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

.userPhotoWrapper{
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1.8px solid var(--accent-color);
    border-radius: 50%;
  
    overflow: hidden;
    
    @media (min-width: 768px) {
        width: 44px;
        height: 44px;
    }
}
