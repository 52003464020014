@import '../../styles/variables.css';

.formTitle {
    margin-bottom: 20px;
  
    font-size: 12px;
    font-weight: 900;
    line-height: 1.5;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--darken-color);
    text-align: center;
  
    @media (min-width: 768px) {
      margin-bottom: 30px;
      font-size: 16px;
  
    }
  }

.formLabel {
    color: var(--darken-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 20px;
  
}

.formInput {
    display: block;

    margin: 8px 0 24px 0;
    padding-left: 15px;
    padding-right: 15px;

    width: 100%;
    height: 58px;

    color: var(--darken-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;

    border-radius: 30px;
    border: 1px solid var(--input-border-color);
    outline: none;
    background-color: var(--secondary-color);

    transition: border-color 0.15s ease-in-out, background-image 0.15s ease-in-out;

    @media (min-width: 768px) {
        font-size: 16px;
    }
}

.formInput:focus {
    border-color: var(--accent-color);
}


.formInput::placeholder {
    color: var(--text-color);
}

.formSelect {
    composes: formInput;
    color: var(--text-color);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    background-image: url(../../../src/assets/icons/selectarrow.png);
    background-repeat: no-repeat;
    background-position: 95% 50%;
    padding-right: 60px;
    height: 78px;
    white-space: normal;
    /* For Firefox: */
    text-overflow: ellipsis;

}

.formSelect::-ms-expand {
    display: none;
    /* remove arrow IE     */
}

.formSelect:focus {
    background-image: url(../../../src/assets/icons/chosenselectarrow.png);
}

.formOption {
    /* wrap text in compatible browsers */
    -moz-white-space: pre-wrap;
    -o-white-space: pre-wrap;
    white-space: pre-wrap;
    /* hide text that can't wrap with an ellipsis */
    overflow: hidden;
    text-overflow: ellipsis;

}

.formTextarea {
    display: block;
    resize: none;

    width: 100%;
    height: 120px;

    padding: 12px 16px;
    margin: 8px 0 24px 0;

    border: 1px solid var(--textarea-border-color);
    border-radius: 30px;

    outline: none;
    color: var(--darken-color);
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.formTextarea:focus {
    border-color: var(--accent-color);

}

.formTextarea::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: var(--text-color);



}

.accent {
    color: var(--accent-color);
    font-weight: 700;
}
.timeHeader{
    margin-top: 24px;
}

.timeWrapper{
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    margin: 12px 0 20px 0;
}

.timeInput {
    display: none;
}

.timeLabel {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 40px;
    
    background-color: var(--second-main-color);
    border-radius: 8px;

    box-shadow: 0 3px 10px rgba(0,0,0,0.2), inset 0 -3px 0 rgba(0,0,0,0.22);
    transition: 0.3s;
    user-select: none;
    cursor: pointer;

}
.timeLabel:hover,
.timeLabel:focus {
    background-color: var(--first-main-color);
    color: var(--secondary-color);
      box-shadow: 0 3px 10px rgba(0,0,0,0.2), inset 0 -3px 0 rgba(0,0,0,0.32);
}

.timeInput:checked + .timeLabel {
    background-color: var(--first-main-color);
    color: var(--secondary-color);
}

.doctorsWrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;

    margin-top: 12px;
    margin-bottom: 20px;
    width: 270px;

   

}

/* для элемента input c type="radio" */
.customRadio>input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

/* для элемента label связанного с .custom-radio */
.customRadio>span {
    display: inline-flex;
    align-items: center;
    user-select: none;
}
  

/* создание в label псевдоэлемента  before со следующими стилями */
.customRadio>span::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid  var(--input-border-color);
    border-radius: 50%;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    background-color: var(--secondary-color);
}

  /* стили при наведении курсора на радио */
.customRadio>input:not(:disabled):not(:checked)+span:hover::before {
    border-color: var(--first-main-color);
}

 /* стили для активной радиокнопки (при нажатии на неё) */
.customRadio>input:not(:disabled):active+span::before {
    background-color: var(--second-main-color);
    border-color: var(--second-main-color);
    
}

/* стили для радиокнопки, находящейся в фокусе */
.customRadio>input:focus+span::before {
    /* box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); */
    border-color: var(--first-main-color);
    background-color: var(--first-main-color);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

  /* стили для радиокнопки, находящейся в состоянии checked  - не работает*/
.customRadio>input:checked+span::before {
    border-color: var(--first-main-color);
    background-color: var(--first-main-color);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}


.line {

    margin: 24px auto 24px auto;
    border: 1px solid var(--line-color);

}

.calendarText{
    margin-bottom: 12px;

}
  

.butWrapper{
    display: flex;
    flex-direction: column;
    gap: 50px;
  
    @media (min-width: 768px) {
        flex-direction: row;
    }
}


.scheduleItem{
    display: flex;
    gap: 10px;
}

.scheduleTime{
    width: 40px;
}

.timeInfo{
    margin: 30px 0 30px 0;
}

.phoneCodeText {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  
    color: var(--darken-color);
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
  
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  .phoneInputWrapper {
    position: relative;
  
  }

  .formPhoneInput{
    composes: formInput;
    padding: 0 0 0 36px;
    @media (min-width: 768px) {
        padding: 0 0 0 43px;
    }
}
