.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.butWrapper{
    display: flex;
    gap: 40px;
    margin-top: 40px;
    justify-content: center;
}