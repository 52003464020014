@import '../../styles/variables.css';

.aside {
    background-color: var(--secondary-color);
    /* color: var(--link-color); */
    padding: 36px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100vh; */
    height: 80vh;
    box-shadow: 12px 1px 80px 8px rgba(0, 0, 0, 0.75);
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 999;

    @media (min-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (min-width: 1200px) {
        width: 290px;
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
    }
}

.sideBarContet {
    margin-bottom: 20px;
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 50px;

    @media (min-width: 1200px) {
        margin-bottom: 32px;
    }
}



.title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
    /* color: var(--darken-color); */
    color: black;

}