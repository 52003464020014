.title {
    color: black;
    font-size: 14pt;
    font-weight: bolder;
}

.caption {
    font-size: 16pt;
    font-style: italic;
    color: rgb(87, 170, 255);
}