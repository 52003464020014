@import '../../../styles/variables.css';

.buttonClose {
  padding: 0;
  cursor: pointer;

  position: fixed;
  top: 14px;
  right: 14px;

  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;

  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.buttonClose:focus,
.buttonClose:hover {
  color: var(--accent-color);
}

.buttonCloseIcon {
  width: 24px;
  height: 24px;

  fill: currentColor;

  @media (min-width: 768px) {
    width: 34px;
    height: 34px;
  }
}